import { ethers } from "ethers";
import ContractDetails from "../Contracts/ContractDetails";
import { Data } from "./Data";

export default async function GetTokenBalance(add) {

    console.log("contractInstance",add)
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      Data.providerLink
    );
    const signer = provider.getSigner(ContractDetails.contract);

    const contractInstance = new ethers.Contract(
      ContractDetails.clientTokenContract,
      ContractDetails.clientTokenABI,
      signer
    );
    console.log("contractInstance",contractInstance)
    const balance = await contractInstance.balanceOf(add);

    Data.isDebug && console.log("parseFloat(balance/1e18);",balance);
    Data.isDebug && console.log("parseFloat(balance/1e18);", parseFloat(balance/1e18));
    return parseFloat(balance/1e18);
  } catch (error) {
    console.error("Error fetching contract data:", error);
    throw error;
  }
}
