import { ethers } from "ethers";
import ContractDetails from "../Contracts/ContractDetails";
import { Data } from "./Data";

export default async function GetTokenLiveRate() {
    console.log("contractInstance")
  try {
    const provider = new ethers.providers.JsonRpcProvider(
      Data.providerLink
    );
    const signer = provider.getSigner(ContractDetails.contract);

    const contractInstance = new ethers.Contract(
      ContractDetails.buySellContract,
      ContractDetails.buySellABI,
      signer
    );
    console.log("contractInstance",contractInstance)
    const liveRate = await contractInstance.tokenPrice();
    const multiplier = await contractInstance.PRICE_MULTIPLIER();

    console.log("rate is ", liveRate);
    console.log("multiplier is ", multiplier);

    Data.isDebug && console.log("Contract data:", parseFloat(liveRate / multiplier));
    return parseFloat(liveRate / multiplier);

  } catch (error) {
    console.error("Error fetching contract data:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
}
