import { ethers } from "ethers";
import { Data } from "./Data";
import ContractDetails from "../Contracts/ContractDetails";
import { toast } from "react-toastify";

export default async function GetChainId() {

    try {
        if (window.ethereum) {
            window.provider = new ethers.providers.Web3Provider(window.ethereum);
            await window.ethereum.enable(); // Request user permission to access their accounts

        } else {
            console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
        const network = await window.provider.getNetwork();
        console.log("in get chain", network);
        const chainId = network.chainId;
        console.log('Chain ID:', chainId);
        if (chainId == ContractDetails.chainAllowed) {
            return true;
        } else {
            toast(`Please select network ${ContractDetails.allowedChainName}`)
            // alert(`Please select your network ${ContractDetails.allowedChainName}`);
            return false;
        }
    } catch (e) {
        Data.isDebug && console.log(e);

        return false;
    }
}