
const baseurl = "https://arbits.bot/jhg7q";
export const ApiPaths = {
    login: `${baseurl}/security/login`,
    commonLogin: `${baseurl}/security/common_login`,
    register: `${baseurl}/register`,
    dashboard: `${baseurl}/user/dashboard`,
    addFund: `${baseurl}/Crypto/add_fund`,
    fundTransaction: `${baseurl}/Crypto/check_transaction`,
    packages: `${baseurl}/membership/get_packages`,
    deposit: `${baseurl}/membership/topup`,
    retopup: `${baseurl}/membership/retopup`,
    transcations: `${baseurl}/transaction`,
    incomes: `${baseurl}/transaction/incomes`,
    withdrawalHistory: `${baseurl}/transaction/withdrawal`,
    reward: `${baseurl}/reward`,
    directTeam: `${baseurl}/team/direct_team`,
    generationTeam: `${baseurl}/team/generation_team`,
    getTransferFund: `${baseurl}/fund/get_transfer_wallet`,
    transferFund: `${baseurl}/fund/transfer`,
    transferFundStatus: `${baseurl}/fund/transfer_status_admin`,
    fundConvert: `${baseurl}/fund/convert`,
    withdraw: `${baseurl}/fund/fund_withdraw`,
    cancelWithdraw: `${baseurl}/fund/cancel_withdraw`,
    setAccount: `${baseurl}/profile/edit_account`,
    developmentBonus: `${baseurl}/reward/development_bonus`,
    blog: `${baseurl}/blog`,
    notification: `${baseurl}/user/notification`,
    editProfile: `${baseurl}/profile/edit_profile`,
    sendForgetOTP: `${baseurl}/user/send_forget_otp`,
    forgotPassword: `${baseurl}/user/forget_password`,
    changePassword: `${baseurl}/user/change_password`,
    supportType: `${baseurl}/support/support_type`,
    support: `${baseurl}/support`,
    supportList: `${baseurl}/support/list`,
    metaRequest: `${baseurl}/user/meta_request`,
    about: `${baseurl}/pages/about_us`,
    otherContent: `${baseurl}/user/other_content`,
    transferFundOtp: `${baseurl}/fund/send_otp`,
    news: `${baseurl}/news`,
    getPlans: `${baseurl}/Membership/get_subscription`,
    fundHistory: `${baseurl}/fund/fund_history`,
    supportChat: `${baseurl}/support/chat_on`,
    checkSponsor: `${baseurl}/register/check_sponsor_exist`,
    checkUsername: `${baseurl}/fund/check_username_exist`,
    ActivationHistory: `${baseurl}/membership/invest_history/`,
    ClaimedHistory: `${baseurl}/transaction/claimed_history/`,
    referralHistory: `${baseurl}/transaction/referral_history/`,
    slotData: `${baseurl}/user/matrix_data`,
    userWalletInfo: `${baseurl}/user/sponsor_info`,
    userWalletInfoByUsername: `${baseurl}/user/sponsor_info_by_username`
}
