import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Data } from "../../Common/Data";
import Loader from "../../Components/Loader/Loader";
import "./Swap.css";
import ContractDetails from "../../Contracts/ContractDetails";
import { ToastContainer, toast } from "react-toastify";
import getAddress from "../../Common/GetAddress";
import GetChainId from "../../Common/GetChainId";
import GetTokenLiveRate from "../../Common/GetTokenLiveRate";
import GetTokenBalance from "../../Common/GetTokenBalance";
import GetFloatValue from "../../Common/GetFloatValue";
import GetUSDTBalance from "../../Common/GetUsdtBalance";
// import Trade from './../../Images/trade.jpg'
import ConnectButton from "../../Components/ConnectButton";
import Logo from './../../Images/logo.png'
import { useSelector } from "react-redux";
const Swap = () => {
    const [coinAmount, setCoinAmount] = useState("");
    const [buyAmount, setBuyAmount] = useState("")
    const [coinAmountError, setCoinAmountError] = useState();
    const [loading, setLoading] = useState(false);
    const [tokenLiveRate, setTokenLiveRate] = useState(0);
    const [amountInUSDT, setAmountInUSDT] = useState(0);
    const [tokenBalance, setTokenBalance] = useState();
    const [checkBuySell, setCheckBuySell] = useState("buy");
    const [usdtBalance, setUsdtBalance] = useState("");
    const [valueGetOnBuy, setValueGetOnBuy] = useState("");
    const [valueGetOnSell, setValueGetOnSell] = useState("");
    const walletAdd = useSelector((state) => state.account.value);
    let x = 0;
    const { BigInt } = window;
    useEffect(() => {
        if (x == 0) {
            try {
                console.log("walletAdd", walletAdd)
                if (walletAdd) {
                    FetchData();
                    FetchCoinBalance();
                    fetchUSDTBalance();
                }
            } catch (e) {
                console.log("e", e)
            }
            x = 1;
        }
    }, [walletAdd]);
    const toastSuccess = (msg) => toast.success(msg);
    const toastFailed = (msg) => toast.error(msg);

    async function FetchCoinBalance() {
        console.log("walletAddaaaa", walletAdd)
        let tokenBal = await GetTokenBalance(walletAdd);
        setTokenBalance(GetFloatValue(tokenBal, 4));
    }
    async function fetchUSDTBalance() {
        console.log("walletAdd22222", walletAdd)
        const coinBal = await GetUSDTBalance(walletAdd);
        console.log("coinBal", coinBal)
        setUsdtBalance(GetFloatValue(coinBal, 2));
    }

    async function FetchData() {
        setLoading(true);
        try {
            const livePrice = await GetTokenLiveRate();
            setTokenLiveRate(livePrice);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    function calculateBuyAmount(amount) {
        Data.isDebug && console.log({ tokenLiveRate, amount });
        setBuyAmount(amount);
        let amt = amount / tokenLiveRate;
        setValueGetOnBuy(amt);
    }
    function calculateSellAmount(amount) {
        Data.isDebug && console.log({ tokenLiveRate, amount });
        setCoinAmount(amount);
        let amt = tokenLiveRate * amount;
        setValueGetOnSell(amt)
    }

    async function increaseAllowance() {
        setLoading(true);
        try {
            const { ethereum } = window;
            if (ethereum) {
                let chain = await GetChainId(walletAdd);
                if (chain) {
                    const newAmount = BigInt(coinAmount * 1e18);
                    Data.isDebug && Data.isDebug && console.log("coinAmount", coinAmount);
                    Data.isDebug && Data.isDebug && console.log("tokenBalance", tokenBalance);

                    if (parseFloat(coinAmount) <= parseFloat(tokenBalance)) {
                        const provider = new ethers.providers.Web3Provider(ethereum);
                        const signer = provider.getSigner();
                        const busdInstance = new ethers.Contract(
                            ContractDetails.clientTokenContract,
                            ContractDetails.clientTokenABI,
                            signer
                        );
                        // Data.isDebug && console.log("Instance : " + busdInstance);
                        // Data.isDebug && console.log("value", x)
                        let inc = await busdInstance.approve(
                            ContractDetails.buySellContract,
                            newAmount,
                            { value: ethers.utils.parseEther("0") }
                        );
                        await inc.wait();
                        sellTokenFunc(newAmount);
                        Data.isDebug && console.log("Tr Hash 1: " + inc.hash);
                    } else {
                        toastFailed("Insufficient funds in your wallet");
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            }
        } catch (error) {
            alert("Transaction Failed");
            Data.isDebug && console.log("error", error);
            setLoading(false);
        }
    }
    async function sellTokenFunc(newAmount) {
        try {
            const { ethereum } = window;
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                // Data.isDebug && console.log("11", provider);
                const signer = provider.getSigner();
                // Data.isDebug && console.log("22", signer);
                const contractInstance = new ethers.Contract(
                    ContractDetails.buySellContract,
                    ContractDetails.buySellABI,
                    signer
                );
                let inc = await contractInstance.sellTokens(newAmount);
                await inc.wait();
                toastSuccess("Transaction Successful");
                setLoading(false);
                FetchCoinBalance();
            }
        } catch (error) {
            Data.isDebug && console.log("error12", error);
            alert("something went wrong");
            setLoading(false);
        }
    }
    async function buyIncreaseAllowance() {
        setLoading(true);
        try {
            const { ethereum } = window;
            if (ethereum) {
                let chain = await GetChainId(walletAdd);
                if (chain) {
                    const connectedAddress = await getAddress();
                    Data.isDebug && console.log("9999999999999", { connectedAddress, walletAdd })

                    const newAmount = BigInt(buyAmount * 1e6);
                    Data.isDebug && Data.isDebug && console.log("newAmount", newAmount);

                    if (parseFloat(buyAmount) <= parseFloat(usdtBalance)) {
                        const provider = new ethers.providers.Web3Provider(ethereum);
                        const signer = provider.getSigner();
                        const busdInstance = new ethers.Contract(
                            ContractDetails.usdtTokenContract,
                            ContractDetails.usdtTokenABI,
                            signer
                        );
                        // Data.isDebug && console.log("Instance : " + busdInstance);
                        // Data.isDebug && console.log("value", x)
                        let inc = await busdInstance.increaseAllowance(
                            ContractDetails.buySellContract,
                            newAmount,
                            { value: ethers.utils.parseEther("0") }
                        );
                        await inc.wait();
                        buyTokenFunc(newAmount);
                        Data.isDebug && console.log("Tr Hash 1: " + inc.hash);
                    } else {
                        toastFailed("Insufficient funds in your wallet");
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            }
        } catch (error) {
            alert("Transaction Failed");
            Data.isDebug && console.log("error", error);
            setLoading(false);
        }
    }
    async function buyTokenFunc() {
        const newAmount = BigInt(valueGetOnBuy * 1e18);
        try {
            const { ethereum } = window;
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                // Data.isDebug && console.log("11", provider);
                const signer = provider.getSigner();
                // Data.isDebug && console.log("22", signer);
                const contractInstance = new ethers.Contract(
                    ContractDetails.buySellContract,
                    ContractDetails.buySellABI,
                    signer
                );
                let inc = await contractInstance.buyTokens(newAmount);
                await inc.wait();
                console.log("inc", inc.hash)
                toastSuccess("Transaction Successful");
                setLoading(false);
                FetchData();
                FetchCoinBalance();
                fetchUSDTBalance()
            }
        } catch (error) {
            Data.isDebug && console.log("error12", error);
            alert("something went wrong");
            setLoading(false);
        }
    }

    return (
        <>
            <ToastContainer />
            {loading ? <Loader /> : null}
            <div className="swapSaction">
                <div className="swapLogo">
                    <img src={Logo} alt="" />
                    <ConnectButton />
                </div>
                {/* <img className="swapBg" src={Trade} alt="" /> */}
                <div className="addfundDiv inputPrimary swapDiv">
                    {
                        checkBuySell == "buy" ?
                            <>
                                <div className="mb-4">
                                    <div className="liveRateDiv liveRateDivInvestment" style={{ marginBottom: "7px" }}>
                                        <h5>{Data.coinName} Live Rate</h5>
                                        <div>
                                            <p>$ {tokenLiveRate}</p>
                                        </div>
                                    </div>
                                    <div className="liveRateDiv liveRateDivInvestment" >
                                        <h5>{Data.coinName} Wallet</h5>
                                        <div>
                                            <p>{usdtBalance} {Data.coinName}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="buyToken">
                                    <div className="inputDivIcon">
                                        <input
                                            min={1}
                                            required
                                            type="text"
                                            placeholder="Enter amount"
                                            value={buyAmount}
                                            onChange={(e) => calculateBuyAmount(e.target.value)}
                                        />
                                        <i>
                                            <p>{Data.coinName}</p>
                                        </i>
                                    </div>
                                    <p className="errorMsg">{coinAmountError}</p>
                                    <div id="pkgInfo" className="mt-2">
                                        <p>You will get</p>
                                        <p>{valueGetOnBuy} token</p>
                                    </div>
                                    <button
                                        className="btnPrimary mt-2"
                                        onClick={() => buyIncreaseAllowance()}
                                    >
                                        Buy Coin
                                    </button>

                                    <div className="changeBuySell">
                                        <p>If you want to sell your token then click here</p>
                                        <button onClick={() => setCheckBuySell("sell")}>Sell</button>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="mb-4">
                                    <div className="liveRateDiv liveRateDivInvestment" style={{ marginBottom: "7px" }}>
                                        <h5>{Data.coinName} Live Rate</h5>
                                        <div>
                                            <p>$ {tokenLiveRate}</p>
                                        </div>
                                    </div>
                                    <div className="liveRateDiv liveRateDivInvestment" >
                                        <h5>Token Wallet</h5>
                                        <div>
                                            <p>{tokenBalance} Token</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sellToken">
                                    <div className="inputDivIcon">
                                        <input
                                            min={1}
                                            required
                                            type="text"
                                            placeholder="Enter amount"
                                            value={coinAmount}
                                            onChange={(e) => calculateSellAmount(e.target.value)}
                                        />
                                        <i>
                                            <p>Token</p>
                                        </i>
                                    </div>
                                    <p className="errorMsg">{coinAmountError}</p>
                                    <div id="pkgInfo" className="mt-2">
                                        <p>You will get</p>
                                        <p>$ {valueGetOnSell}</p>
                                    </div>
                                    <button
                                        className="btnPrimary mt-2"
                                        onClick={() => increaseAllowance()}
                                    >
                                        Sell Coin
                                    </button>

                                    <div className="changeBuySell">
                                        <p>If you want to buy token then click here</p>
                                        <button onClick={() => setCheckBuySell("buy")}>Buy</button>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    );
};

export default Swap;
