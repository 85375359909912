import BUSD_ABI from "../Contracts/BUSD_ABI.json";
import contractABI from "../Contracts/contract_ABI.json";
import tokenABI from "../Contracts/Token_ABI.json";
import buySellABI from "../Contracts/BuySellABI.json";
import usdtTokenABI from "../Contracts/usdtTokenContract.json";
import clientTokenABI from '../Contracts/ClientTokenABI.json'
// const arr  = {contract:"0x7928986fe9728c35cD49a0240281f03351Ce5672",contractABI:contractABI,BUSD:"0xe37b70Ef457899F0afdFB71CEd2671CFA84ef770",BUSD_ABI:BUSD_ABI}

const arr = {
  chainAllowed: 137,
  allowedChainName: 'Polygon Mainnet',
  contract: "0xe4a6c136C357859368fb37233F14B5f8bfc18828",
  contractABI: contractABI,
  BUSD: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  BUSD_ABI: BUSD_ABI,
  contract2: "0xC6cBD0228256a1E215474b34B14A3B6817e9040C",
  tokenABI: tokenABI,
  buySellContract:"0x53305946760b5F94742CeA63a32ad00305140908",
  buySellABI:buySellABI,
  usdtTokenContract:"0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  usdtTokenABI:usdtTokenABI,
  clientTokenContract:'0x8eee8af293BF600eBca4bd524Bcc7a42a1785722',
  clientTokenABI:clientTokenABI,
  multiplier: 1e6
};

////////////////testnet//////////////////
// const arr = {
//   contract: "0x98F7A9bB657dE1a6E7BaD6655483850c74A878e1",
//   contractABI: contractABI,
//   BUSD: "0x325a4deFFd64C92CF627Dd72d118f1b8361c5691",
//   BUSD_ABI: BUSD_ABI,
//   token: "0xa30C22b19fCd4A7209d9E55e15D890a350c6Ae4F",
//   tokenABI: tokenABI,
// };


export const ContractDetails = arr;
export default ContractDetails;
