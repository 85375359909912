import { ethers } from "ethers";
import ContractDetails from "../Contracts/ContractDetails";
import { Data } from "./Data";

export default async function GetUSDTBalance(id) {
    try {
       const provider = new ethers.providers.JsonRpcProvider(
      Data.providerLink
    );
      const signer = provider.getSigner(ContractDetails.contract);
      const contractinstance = new ethers.Contract(
        ContractDetails.usdtTokenContract,
        ContractDetails.usdtTokenABI,
        signer
      );
      // console.log( contractinstance)
      const bl = await contractinstance.balanceOf(id);
      console.log("bal",bl);
      return bl/1e6;
    } catch (e) {
      console.log("GetBalance", e);
    }
}
